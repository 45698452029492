import React, { useState, useEffect } from 'react';
import {useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import { BsUmbrella, BsPerson  } from "react-icons/bs";
import MaskedFormControl from 'react-bootstrap-maskedinput'


const SimpleQuoteForm = (props) => {
    const [years, setYears] = useState([]);
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMake, setSelectedMake] = useState('');
    const [selectedModel, setSelectedModel] = useState('');
    const [vehicles, setVehicles] = useState([]);
    const [selectedInsured, setSelectedInsured] = useState('');
    const [isInsured, setIsInsured] = useState(false);
    const [selectedInsuredDuration, setSelectedInsuredDuration] = useState('');
    const [isHomeowner, setIsHomeowner] = useState(false);
    const [includeHomeInsurance, setIncludeHomeInsurance] = useState(false);
    const [birthdate, setBirthdate] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [gender, setGender] = useState('');
    const [isMarried, setIsMarried] = useState('');
    const [hasDui, setHasDui] = useState('');
    const [isMilitary, setIsMilitary] = useState('');
    const [hasAccidents, setHasAccidents] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [personalIndex, setPersonalIndex] = useState(1);
    const [dobMonth, setDobMonth] = useState('');
    const [dobDay, setDobDay] = useState('');
    const [dobYear, setDobYear] = useState('');
    const [confirm, setConfirm] = useState(false);
    const [required, setRequired] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [progress, setProgress] = useState('');
    const [currentTab, setCurrentTab] = useState(0);

    const history = useHistory();

    const formWizardRef = React.createRef();

    useEffect(async () => {
        try {
            const leadId = localStorage.getItem('leadId');
            if(leadId == null){
                
                const yearResponse = await fetch("https://humble100.leadmanagertraining.com/api/autoquote/getYears", { 
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                });
                const yearRange = await yearResponse.json();
                setYears(yearRange);
                console.log(years.years);
                InsertProgress("Personal");
            } else {
                history.push('/Confirmation');
            }
        }
        catch(e) {
            console.log('Submit Failed', e);
        }
    }, []);

    const onSubmit = (e) => {
        e.preventDefault(); 
    }

    const InsertProgress = (tab) => {
        console.log("Inside InsertProgress");
        const response = fetch("https://humble100.leadmanagertraining.com/api/autoquote/InsertProgress?tab=" + tab, { 
            method: 'POST',
            
        });
        const result = response;
        //setProgress(result);
    }

    const handleComplete = async () => {
        const request = {
            address: address,
            city: city,
            state: state,
            zipCode: zip,
            firstName: firstName,
            lastName: lastName,
            phone: phone, 
            email: email,
            isMarried: isMarried == "" ? false : isMarried,
            gender: gender,
            insuredCompany: selectedInsured,
            birthdate: birthdate == "" ? new Date() : birthdate,
            vehicles: vehicles,
            hasDui: hasDui == "true" ? "Yes" : "No",
            hasAccidents: hasAccidents == "true" ? "Yes" : "No",
            isMilitary: isMilitary == "true" ? "Yes" : "No",
            includeHomeInsurance: includeHomeInsurance
        };
        try {
                const response = await fetch('https://humble100.leadmanagertraining.com/api/autoquote', { 
                    method: 'POST', 
                    body: JSON.stringify(request),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const leadProsper = await response.json();
                console.log(leadProsper);
                if(leadProsper.status != "ACCEPTED")
                {
                    alert("An error has occurred, please try again later.");
                }
                else {
                    InsertProgress("Lead Id: " + leadProsper.lead_id);
                    localStorage.setItem('leadId', leadProsper.lead_id);
                    history.push('/Confirmation');
                }
            }
            
            catch(e) {
                console.log('Submit Failed', e);
            }
    };

    const handleYearChange = async (e) => {
        setSelectedYear(e);
        InsertProgress("Vehicles [Select Make]");
        const response = await fetch("https://humble100.leadmanagertraining.com/api/autoquote/getMakes?year=" + e, { 
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const jsonResponse = await response.json();
        setMakes(jsonResponse);
        
    };

    const handleMakeChange = async (e) => {
        setSelectedMake(e);
        InsertProgress("Vehicles [Select Model]");
        const response = await fetch("https://humble100.leadmanagertraining.com/api/autoquote/getModels?year=" + selectedYear + "&make=" + e, { 
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const jsonResponse = await response.json();
        setModels(jsonResponse);
    };

    const handleModelChange = async (e) => {
        setSelectedModel(e);
        var vehicle = {"year": selectedYear, "make": selectedMake, "model": e};
        var arrVehicles = vehicles;
        
        arrVehicles.push(vehicle);
        setVehicles(arrVehicles);
        console.log(vehicles);
    };

    const handleInsuredChange = async (e) => {
        setSelectedInsured(e);
    };

    const handleInsuredDurationChange = async (e) => {
        setSelectedInsuredDuration(e);
        formWizardRef.current?.nextTab();
    };

    const handlePersonalContinue = async () => {
        var index = personalIndex;
        var requiredField = false;
        var emailInvalid = false;
        var message = "Field is required!"
        switch(index) {
            // case 1:
            //     InsertProgress("Personal [Birthdate]");
            //     requiredField = birthdate == "" && true;
            //     break;
            // case 2:
            //     InsertProgress("Personal [Gender]");
            //     requiredField = gender == "" && true;
            //     break;
            // case 3:
            //     InsertProgress("Personal [Married]");
            //     requiredField = isMarried == "" && true;
            //     break;
            // case 4:
            //     InsertProgress("Personal [Accidents]");
            //     requiredField = hasAccidents == "" && true;
            //     break;
            // case 5:
            //     InsertProgress("Personal [Dui]");
            //     requiredField = hasDui == "" && true;
            //     break;
            case 1:
                InsertProgress("Personal [Name]");
                requiredField = firstName == "" || lastName == "" && true;
                break;
            case 2:
                InsertProgress("Personal [Address]");
                requiredField = address == "" || city == "" || state == "" || zip == "" && true;
                break;
            // case 8:
            //     InsertProgress("Personal [Email]");
            //     if(email == "")
            //     {
            //         requiredField = true;
            //     } else {
            //         let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
            //         if (!emailRegex.test(email)) {
            //             requiredField = true;
            //             message = "Invalid email"
            //         }
            //     }
            //     break;
            // case 9:
            //     InsertProgress("Personal [Phone]");
            //     requiredField = phone == "" && true;
            //     break;
            default:
              break;
        }
        setRequired(requiredField);
        setErrorMessage(message);
        console.log(required);
        if(requiredField == false){
            if(personalIndex < 2){
                index++;
            }
            setPersonalIndex(index);
            console.log(personalIndex);
            if(personalIndex >= 2){
                formWizardRef.current?.nextTab();
            }
        }
    };
    const handlePersonalBack = async () => {
        setRequired(false);
        var index = personalIndex;
        if(index > 1)
        {
            index--;
        }
        setPersonalIndex(index);
        console.log(personalIndex);
        
    };
    const handleAddAnotherVehicle = async (e) => {
        InsertProgress("Vehicles [Add Another Vehicle]");
        setSelectedMake("");
        setSelectedModel("");
        setSelectedYear("");
    };

    const tabChanged = ({prevIndex, nextIndex}) => {
        console.log("prevIndex", prevIndex);
        console.log("nextIndex", nextIndex);
        console.log(confirm);
        if(prevIndex != currentTab){
            if(prevIndex == 1){
                console.log(vehicles);
                InsertProgress("Insured");
            }
            if(prevIndex == 2 ){
                InsertProgress("Homeowner");
            }
            if(prevIndex == 3 ){
                console.log("Inside Tab Changed Personal");
                InsertProgress("Confirm");
            }
            if(prevIndex == 4 ){
                InsertProgress("Review");
            }
        }
        setCurrentTab(prevIndex);
    };
    const handleConfirmClick = () => {
        InsertProgress("Review [Clicked Confirm]");
        var valConfirm = confirm;
        setConfirm(!valConfirm);
        console.log(confirm);
    };
    const insCarriersList = require('../data/inscarriers.json');
    const stateList = require('../data/states.json');
    const days = [];
    for(let i=1; i <= 31; i++){
        days.push(i);
    }
    const dobYears = [];
    for(let i=2008; i >= 1940; i--){
        dobYears.push(i);
    }
    return (
        <div>
            <div style={{textAlign: 'center'}}>
                <Image src="FullCircleLogo.jpeg" fluid />
                {/* <img src="Studio-Project.png" height="150" /> */}
            </div>
            <FormWizard ref={formWizardRef} shape="circle" color="#1890ff" onComplete={handleComplete}
             onTabChange={tabChanged} 
             backButtonTemplate={(handleBack) => (
                <Button variant="primary" className="base-button" onClick={handleBack} style={{display: personalIndex == 1 || personalIndex == 10 ? 'block' : 'none'}}>
                  Back
                </Button>
              )}
              nextButtonTemplate={(handleNext) => (
                <button className="base-button" onClick={handleNext} style={{display: 'none'}}>
                  next
                </button>
              )}
              finishButtonTemplate={(handleComplete) => (
                <button className="finish-button" onClick={handleComplete} style={{display: 'none'}}>
                  finish
                </button>
              )}>
                <FormWizard.TabContent title="Personal" icon="fa fa-user">
                    <Row>
                        <Col sm={12}>
                            <h1>Personal Information</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{span: 6, offset: 3}}>
                        {personalIndex == 1 ?
                            <Form.Group style={{textAlign: 'center', marginTop: 20}}>
                                <Form.Label style={{fontWeight: 'bold'}}>Please provide your name</Form.Label>
                                <Form.Control type="text" placeholder="First Name" style={{marginBottom: 10}} onChange={(e) => setFirstName(e.target.value)} value={firstName} autocomplete="given-name" />
                                <Form.Control type="text" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} value={lastName} autocomplete="family-name" />
                            </Form.Group>
                            : 
                            <Form.Group style={{textAlign: 'center', marginTop: 20}}>
                                <Form.Label style={{fontWeight: 'bold'}}>Please provide your Address?</Form.Label>
                                <Form.Control type="text" placeholder="Address" style={{marginBottom: 10}} onChange={(e) => setAddress(e.target.value)} value={address} autocomplete="street-address" />
                                <Row>
                                    <Col xs={4}>
                                        <Form.Control type="text" placeholder="City" onChange={(e) => setCity(e.target.value)} value={city} />
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Control as="select" onChange={(e) => setState(e.target.value)} value={state} className="form-select" placeholder="State">
                                            <option value=''></option>
                                            {stateList.sort().map(i => 
                                                <option key={i.abbreviation} value={i.abbreviation}>{i.name}</option>
                                            )}
                                        </Form.Control>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Control type="text" placeholder="Zip" onChange={(e) => setZip(e.target.value)} value={zip} autocomplete="postal-code" />
                                    </Col>
                                </Row>
                            </Form.Group>
                            // : personalIndex == 8 ?
                            // <Form.Group style={{textAlign: 'center', marginTop: 20}}>
                            //     <Form.Label style={{fontWeight: 'bold'}}>Please provide your email</Form.Label>
                            //     <Form.Control type="email" placeholder="Email" style={{marginBottom: 10}} onChange={(e) => setEmail(e.target.value)} value={email} />
                            // </Form.Group>
                            // :
                            // <Form.Group style={{textAlign: 'center', marginTop: 20}}>
                            //     <Form.Label style={{fontWeight: 'bold'}}>Please provide your phone number</Form.Label>
                            //     <MaskedFormControl type='text' name='Phone' mask='(111)111-1111' onChange={(e) => setPhone(e.target.value)} value={phone}  />
                            //     {/* <Form.Control type="text" placeholder="Phone" style={{marginBottom: 10}} onChange={(e) => setPhone(e.target.value)} value={phone} /> */}
                            // </Form.Group>
                        }
                        <Alert key="danger" variant="danger" style={{display: required == true ? 'block' : 'none'}}>
                            {errorMessage}
                        </Alert>
                        <Button style={{right: 10, position: 'absolute', marginTop: 10}} variant="primary" onClick={handlePersonalContinue}>Continue</Button>
                        <Button style={{left: 20, position: 'absolute', marginTop: 10, display: personalIndex == 1 ? 'none' : 'block'}} variant="primary" onClick={handlePersonalBack}>Back</Button>
                        </Col>
                    </Row>
                </FormWizard.TabContent>
                <FormWizard.TabContent title="Insured" icon="fa fa-umbrella">
                    <Row>
                        {isInsured == false && 
                        <Col sm={12}>
                            <h1>Are You Currently Insured?</h1>
                        </Col>
                        }
                    </Row>
                    <Row>
                        <Col lg={{span: 6, offset: 3}}>
                        {isInsured == false && 
                            <div style={{marginTop: 30}} >
                                <Button size="lg" style={{marginRight: 20}} variant={isInsured==true ? "success" : "primary"} onClick={() => setIsInsured(true)}>Yes</Button>
                                <Button size="lg" variant="primary" onClick={() => formWizardRef.current?.nextTab()}>No</Button>
                            </div>
                        }
                            <div>
                            {isInsured == true && selectedInsured == "" ?
                                <div style={{marginTop: 30}}>
                                    <Form.Label style={{fontWeight: 'bold'}}><h1>What company are you insured with?</h1></Form.Label>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-evenly'}}>
                                        {insCarriersList.map(i => 
                                            <Button style={{marginTop: 10, marginRight: 10, width: '35%'}} variant="primary" onClick={() => handleInsuredChange(i)}>{i}</Button>
                                        )}
                                    </div>
                                    {/* <Form.Control as="select" onChange={handleInsuredChange} value={selectedInsured} className="form-select">
                                        <option value=''></option>
                                        {insCarriersList.sort().map(i => 
                                            <option key={i} value={i}>{i}</option>
                                        )}
                                    </Form.Control> */}
                                </div>
                                :
                                <div style={{marginTop: 30}}>
                                    {isInsured == true && selectedInsured != "" &&
                                    <div>
                                    <Form.Label style={{fontWeight: 'bold'}}><h1>How long have you been with {selectedInsured}?</h1></Form.Label>
                                    
                                    
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <Button style={{marginTop: 10, width: '75%'}} variant="primary" onClick={() => handleInsuredDurationChange('less than a year')}>less than a year</Button>
                                            <Button style={{marginTop: 10, width: '75%'}} variant="primary" onClick={() => handleInsuredDurationChange('1-2 years')}>1-2 years</Button>
                                            <Button style={{marginTop: 10, width: '75%'}} variant="primary" onClick={() => handleInsuredDurationChange('2-3 years')}>2-3 years</Button>
                                            <Button style={{marginTop: 10, width: '75%'}} variant="primary" onClick={() => handleInsuredDurationChange('3-5 years')}>3-5 years</Button>
                                            <Button style={{marginTop: 10, width: '75%'}} variant="primary" onClick={() => handleInsuredDurationChange('5+ years')}>5+ years</Button>
                                    </div>
                                    </div>
                                    }
                                </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </FormWizard.TabContent>
                <FormWizard.TabContent title="Homeowner" icon="fa fa-home">
                    <Row>
                    {isHomeowner == false &&
                        <Col sm={12}>
                            <h1>Are You A Home Owner?</h1>
                        </Col>
                    }
                    </Row>
                    <Row>
                        <Col lg={{span: 6, offset: 3}}>
                        {isHomeowner == false &&
                            <div style={{marginTop: 30}} >
                                <Button size="lg" style={{marginRight: 20}} variant={isHomeowner == true ? "success" : "primary"} onClick={() => setIsHomeowner(true)}>Yes</Button>
                                <Button size="lg" variant="primary" onClick={() => formWizardRef.current?.nextTab()}>No</Button>
                            </div>
                        }
                            {isHomeowner == true &&
                            <div>
                                <div style={{textAlign: 'center', marginTop: 30}}>
                                    <div>
                                        <Form.Label style={{fontWeight: 'bold'}}><h1>Want to Include a Home Insurance Quote to save even more?</h1></Form.Label>
                                    </div>
                                    <div>
                                        <Button size="lg" style={{marginRight: 20}} variant="primary" onClick={() => {setIncludeHomeInsurance(true); formWizardRef.current?.nextTab();}}>Yes</Button>
                                        <Button size="lg" variant="primary" onClick={() => {setIncludeHomeInsurance(false); formWizardRef.current?.nextTab()}}>No</Button>
                                    </div>
                                </div>
                            </div>
                            }
                        </Col>
                    </Row>
                </FormWizard.TabContent>
                <FormWizard.TabContent title="Confirm" icon="fa fa-check">
                    <Row>
                        <Col sm={12}>
                            <h1>Confirm</h1>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col lg={{span: 6, offset: 3}}>
                            <table width="100%">
                                <tbody style={{textAlign: 'left'}}>
                                {vehicles.map((v, index) =>
                                    <tr>
                                        <td>Vehicle {index}</td>
                                        <td>{v.year} {v.make} {v.model}</td>
                                    </tr>
                                )}
                                    <tr>
                                        <td>Are you currently insured?</td>
                                        <td>{isInsured == true ? "Yes" : "No"}</td>
                                    </tr>
                                    {isInsured == true &&
                                    <tr>
                                        <td>What company are you insured with?</td>
                                        <td>{selectedInsured}</td>
                                    </tr>
                                    }
                                    {isInsured == true &&
                                    <tr>
                                        <td>How Long Have You Been with {selectedInsured}</td>
                                        <td>{selectedInsuredDuration}</td>
                                    </tr>
                                    }
                                    <tr>
                                        <td>Are you a home owner?</td>
                                        <td>{isHomeowner == true ? "Yes" : "No"}</td>
                                    </tr>
                                    {isHomeowner == true &&
                                    <tr>
                                        <td>Want to Include a Home Insurance Quote to save even more?</td>
                                        <td>{includeHomeInsurance == true ? "Yes" : "No"}</td>
                                    </tr>
                                    }
                                    <tr>
                                        <td>Date of Birth</td>
                                        <td>{birthdate}</td>
                                    </tr>
                                    <tr>
                                        <td>Gender</td>
                                        <td>{gender}</td>
                                    </tr>
                                    <tr>
                                        <td>Are you Married</td>
                                        <td>{isMarried == "true" ? "Yes" : "No"}</td>
                                    </tr>
                                    <tr>
                                        <td>Any at-fault Accidents in the Last 3 years?</td>
                                        <td>{hasAccidents == "true" ? "Yes" : "No"}</td>
                                    </tr>
                                    <tr>
                                        <td>DUI in the last 3 years?</td>
                                        <td>{hasDui == "true" ? "Yes" : "No"}</td>
                                    </tr>
                                    <tr>
                                        <td>First Name</td>
                                        <td>{firstName}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Name</td>
                                        <td>{lastName}</td>
                                    </tr>
                                    <tr>
                                        <td>Are You or Your Spouse an Active Member, or an Honorably Discharged Veteran of the US Military?</td>
                                        <td>{isMilitary == "true" ? "Yes" : "No"}</td>
                                    </tr>
                                    <tr>
                                        <td>Address</td>
                                        <td>{address}</td>
                                    </tr>
                                    <tr>
                                        <td>City</td>
                                        <td>{city}</td>
                                    </tr>
                                    <tr>
                                        <td>State</td>
                                        <td>{state}</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>{email}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row> */}
                    <Row style={{marginTop: 20, textAlign: 'left'}}>
                        <Col xs={12} lg={{span: 6, offset: 3}}>
                        {/* <Form.Check // prettier-ignore
                            checked={confirm}
                            type="checkbox"
                            id="checkbox"
                            onClick={handleConfirmClick}
                            label={<div style={{marginLeft: 20}}><p>By clicking this "Check Box", I confirm that information that I have provided is accurate and I agree to the following consents:</p><p>By clicking “Check Box” above, I give prior express written consent to receive marketing communications about insurance products and services via automated dialing systems, pre-recorded calls, artificial voice, text messages, picture messages, and emails from Full Circle Group of GA, LLC, DBA Full Circle Insurance and EZ Marketing, LLC, a marketing partner that will call, text and email me at the phone number and/or e-mail address that I provided or confirmed above, including landlines and wireless numbers, if applicable, even if the phone number is on a corporate, state or national do not call list. By clicking “Check Box”, I represent that I am at least 18 years of age, and consent and agree to tour <a href="Terms" target="_blank">Privacy Policy and Terms and Conditions</a>, including the arbitration provision and class action waiver contained therein.</p> <p>There is no charge to use our service, so you do not need to consent to purchase goods or services, and you may revoke your consent at any time. If you do not agree to the foregoing, then Do Not click “Check Box”.</p> To receive quotes without providing consent, please call or text 770-406-5829. Carrier data rates may apply.</div>}
                        /> */}
                            <div style={{textAlign: 'center', marginBottom: 20}}>
                                <Button size="lg" style={{width: '75%'}} variant="primary" onClick={handleComplete}>{includeHomeInsurance == false ? "Get My Auto Quote" : "Get My Auto & Home Quote"}</Button>
                            </div>
                            <div>
                                <p>Full Circle Insurance WILL NOT SELL YOUR DATA.  By clicking this "Get My Auto Quote" or "Get My Auto & Home Quote" , I confirm that information that I have provided is accurate and I agree to the following consents:</p>
                                <p>By clicking "Get My Auto Quote" or "Get My Auto & Home Quote", I give prior express written consent to receive marketing communications about insurance products and services via automated dialing systems, text messages, picture messages, and emails from <span style={{color: 'blue'}}>Full Circle Group of GA, LLC, DBA Full Circle Insurance and EZ Marketing, LLC</span>.  I understand that EZ Marketing LLC is a marketing partner that works on behalf of Full Circle Group of GA, LLC, DBA Full Circle Insurance that will call, text and email me at the phone number and/or e-mail address that I provided or confirmed above, including landlines and wireless numbers, if applicable, even if the phone number is on a corporate, state or national do not call list. By clicking "Get My Auto Quote" or "Get My Auto & Home Quote", I represent that I am at least 18 years of age, and consent and agree to tour <a href="Terms" target="_blank">Privacy Policy and Terms and Conditions</a>, including the arbitration provision and class action waiver contained therein.</p>
                                <p>There is no charge to use our service, so you do not need to consent to purchase goods or services, and you may revoke your consent at any time. If you do not agree to the foregoing, then Do Not click "Get My Auto Quote" or "Get My Auto & Home Quote".  <strong>To receive quotes without providing consent, please call or text 770-406-5829. Carrier data rates may apply.</strong></p>
                            </div>
                            
                        </Col>
                    </Row>
                </FormWizard.TabContent>
            </FormWizard>
            {/* add style */}
            <style>{
            `
            @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css");
            `}</style>
        </div>
    )
};

export default SimpleQuoteForm;